.controls {
  position: fixed;
  bottom: 0px;
  width: 100%;

  margin-top: auto;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-self: center;
  gap: 12px;

  padding: 0px 24px 24px;
  box-sizing: border-box;
}

.dismiss,
.like,
.share,
.next,
.message {
  width: min(20vw, 180px);
  height: min(20vw, 180px);
  border-radius: 50%;
  font-size: min(10vw, 90px);
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px 0px #00000052;
  background-color: #fffc;
}

.dismiss,
.like,
.share {
  width: 60px;
  height: 60px;
  font-size: 30px;
}

.share {
  display: grid;
  grid-template-columns: max-content 1fr;

  padding: 0px 12px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  width: auto;
  background-color: #02aff0;
  color: #fff;

  svg {
    flex-shrink: 0;
    margin-right: 6px;
    font-size: 28px;
  }
}

.dismiss {
  color: #02aff0;
}

.like {
  color: #d00000;
}

.next {
  color: #02aff0;
  box-shadow: 0px 4px 8px 0px #00000026;
}

.message {
  color: #02aff0;
  box-shadow: 0px 4px 8px 0px #00000026;

  svg {
    margin-bottom: -6px;
  }
}

.rate-wrapper {
  grid-column: 1 / -1;
  background-color: #fffc;
  box-shadow: 0px 4px 8px 0px #00000026;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  border-radius: 30px;
  height: 60px;
}

.rate {
  width: 100%;
  height: 8px;

  & .track-0 {
    height: 8px;
    border-radius: 5px;
    /* background: #02aff0; */
    background: linear-gradient(
      90deg,
      color-mix(in srgb, #02aff0 50%, #fff) 0vw,
      color-mix(in srgb, #02aff0 50%, #fff) 10vw,
      #02aff0 32vw,
      #02aff0 48vw,
      #f3a701 70vw
    );
    margin: 0px 12px;
  }

  & .track-1 {
    height: 8px;
    border-radius: 5px;
    /* background: #02aff0; */
    background: linear-gradient(
      -90deg,
      #f3a701 5vw,
      #02aff0 27vw,
      #02aff0 43vw,
      color-mix(in srgb, #02aff0 50%, #fff) 65vw,
      color-mix(in srgb, #02aff0 50%, #fff) 75vw
    );
    margin: 0px 12px;
    opacity: 0.3;
  }

  & .mark {
    top: -26px;
    padding: 12px;
    margin: 0px -12px;
    height: 36px;
    width: 36px;
    border-radius: 20px;
    font-size: 17px;
    text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .thumb {
    top: -14px;
    height: 36px;
    width: 36px;
    border-radius: 20px;
    font-size: 21px;
    font-weight: 700;
    background-color: #02aff0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus-visible {
      outline: 2px solid #fff;
    }

    &.hot,
    &.not {
      &::before {
        content: "";
        position: absolute;
        bottom: 110%;
        width: 100px;
        height: 87px;
        background: url(../../assets/hot.png);
        -webkit-filter: drop-shadow(1px 1px 2px #00000052);
        filter: drop-shadow(1px 1px 2px #00000052);
      }
    }

    &.not {
      &::before {
        bottom: 120%;
        width: 100px;
        height: 59px;
        background: url(../../assets/not.png);
      }
    }
  }
}

.loading {
  color: transparent !important;
  &::after {
    content: "";
    position: absolute;
    bottom: auto;
    top: auto;
    left: auto;
    right: auto;
    width: 50%;
    height: 50%;
    background-color: transparent;
    border-radius: 50%;
    border: 2px dashed #fff;
    animation: spin 4s linear infinite;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
